<template>
  <div class="ma-4">
    <v-toolbar class="mb-4">
      <v-toolbar-title>System Links</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
      <v-col :cols="3">
        <v-list elevation="4">
          <v-list-item-group v-model="category">
            <v-list-item v-for="{ _id, category, editRoles } of categories" :key="_id" :value="_id" :disabled="!hasRole(editRoles)">
              <v-list-item-content>
                <v-list-item-title>{{ category }}</v-list-item-title>
                <v-list-item-subtitle v-if="!hasRole(editRoles)">No Edit Access</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-dialog v-model="categoryDialog" width="400">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-avatar>
                  <v-icon small>fal fa-plus-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Add Category</v-list-item-title>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title>Add Category</v-card-title>
              <v-card-text>
                <v-text-field v-model="newCategory.category" :error-messages="categoryError" label="Category" item-value="category" item-text="category" outlined></v-text-field>
                <v-select v-model="newCategory.editRoles" :items="editRoles" label="Roles which can add/edit/remove links to/from this category" outlined multiple chips small-chips></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="success" @click="addCategory()">Add Category</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="categoryDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list>
      </v-col>
      <v-col>
        <v-card v-if="isAdmin && category !== ''" class="mb-4">
          <v-card-text>
            <v-select v-model="roles" :items="editRoles" label="Editable Roles" outlined multiple chips small-chips hide-details />
          </v-card-text>
        </v-card>
        <v-expansion-panels v-if="category !== ''" elevation="4">
          <v-expansion-panel v-for="{ _id, name, links } in groups" :key="_id">
            <v-expansion-panel-header>{{ name }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item v-for="{ _id, title, subtitle, icon, href, to } in links" :key="_id">
                  <v-list-item-avatar v-if="icon">
                    <v-icon>{{ icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ title }}</v-list-item-title>
                    <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ href || to }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="startEdit(_id)">
                      <v-icon>fal fa-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-toolbar flat>
                <v-btn outlined @click="addLink(name)">
                  <v-icon left>fal fa-plus-circle</v-icon>
                  Add Link to Group
                </v-btn>
              </v-toolbar>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog v-model="linkDialog" width="600">
      <v-card>
        <v-card-title>{{ editLink._id ? 'Edit Link' : 'Add Link' }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12" md="6">
              <v-text-field v-model="editLink.title" label="Title" outlined></v-text-field>
            </v-col>
            <v-col :cols="12" md="6">
              <v-text-field v-model="editLink.subtitle" label="Subtitle" outlined></v-text-field>
            </v-col>
            <v-col :cols="12" md="8">
              <v-text-field v-model="editLink.href" label="Complete Link" hint="The full web address; include the https at the beginning; if an email link, preface the email address with mailto:" outlined></v-text-field>
            </v-col>
            <v-col :cols="12" md="4">
              <v-text-field v-model="editLink.icon" label="FontAwesome Icon" hint="Click ? to search" outlined>
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" href="https://fontawesome.com/v5/search" target="_blank" icon>
                        <v-icon color="info">fas fa-question-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Click this link to open a new tab to FontAwesome version 5, where you can search for the icon. We have the Pro license, so all icons should be available. When you find the icon you want to use, click on it and copy what is in the class text (i.e. &lt;i class="fas fa-question-circle"&gt;, you would copy the "fas fa-question-circle" but without the quotes).</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col :cols="12">
              <v-select v-model="editLink.accessRoles" label="Who can access this link" hint="Leave blank for everyone" persistent-hint outlined></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" @click="saveLink">{{ editLink._id ? 'Update' : 'Add' }} Link</v-btn>
          <v-btn v-if="editLink._id" text color="error" @click="removeLink">Remove Link</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="linkDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => user.value.roles.includes('Technology Services'))
    const categories = ref([])
    const editRoles = ref(['Technology Services', 'Financial Aid', 'Records Office', 'Center for Calling and Career', 'Employees'])
    const roles = ref([])

    const category = ref('')
    const newCategory = ref({
      category: '',
      editRoles: []
    })
    const categoryDialog = ref(false)
    const categoryError = computed(() => {
      const cat = category.value.category
      if (cat === '') return []
      if (categories.value.filter(({ category }) => category === cat).length > 0) {
        return ['Category with entered name already exists']
      }
      return []
    })
    async function addCategory () {
      await root.$feathers.service('system/link-category').create(newCategory.value)
    }

    const groups = ref([])

    watch(category, (_id) => {
      if (_id !== '') {
        loadLinks()
        const rec = categories.value.find(({ _id: id }) => _id === id)
        if (rec) {
          roles.value = rec.editRoles
        }
      }
    })
    async function loadLinks () {
      const aggregate = [
        { $match: { _id: category.value } },
        { $lookup: { from: 'System-Links', localField: 'groups.links', foreignField: '_id', as: 'links' } }
      ]
      const { data } = await root.$feathers.service('system/link-category').find({ query: { aggregate } })
      if (data.length > 0) {
        const aggGroups = []
        data[0].groups.forEach(({ name, links: l }) => {
          const links = l.map((id) => data[0].links.find(({ _id }) => _id === id))
          aggGroups.push({ name, links })
        })
        groups.value = aggGroups
      } else {
        alert('There was an error loading the link category: The category wasn\'t found in the database. Please contact Technology Services for assistance.')
      }
    }
    const addGroupDialog = ref(false)
    const groupName = ref('')
    async function addGroup () {
      // Make sure the group name is unique
      // If not, give an error
      // If so, add the group to the category
      // await root.$feathers.service('system/link-category').patch(selectedCategory.value._id, { $push: { groups: { name: groupName.value } } })
      // selectedCategory.value.groups.push({ name: groupName.value })
      addGroupDialog.value = false
      groupName.value = ''
    }

    onMounted(async () => {
      const { data } = await root.$feathers.service('system/link-category').find({ query: { $limit: 50, $sort: { category: 1 } } })
      categories.value = data
    })

    function hasRole (roles) {
      return roles.filter((role) => user.value.roles.includes(role)).length > 0
    }

    async function startEdit (id) {
      const rec = await root.$feathers.service('system/link').get(id)
      for (const field in editLink.value) {
        if (field in rec) editLink.value[field] = rec[field]
        else if (field === 'accessRoles') editLink.value[field] = []
        else editLink.value[field] = ''
      }
      addToGroup.value = ''
      linkDialog.value = true
    }
    const linkDialog = ref(false)
    const editLink = ref({
      _id: '',
      title: '',
      subtitle: '',
      href: '',
      icon: '',
      accessRoles: []
    })
    const addToGroup = ref('')
    async function addLink (group) {
      editLink.value = {
        _id: '',
        title: '',
        subtitle: '',
        href: '',
        icon: '',
        accessRoles: []
      }
      addToGroup.value = group
      linkDialog.value = true
    }
    async function saveLink () {
      console.log(editLink.value)
      const temp = { ...editLink.value }
      const id = temp._id
      delete temp._id
      if (id === '') {
        // We need to add the new link to the system/link service
        temp.category = category.value
        temp.group = addToGroup.value
        const { _id } = await root.$feathers.service('system/link').create(temp)
        // Then we need to add the _id from that link to the current category in system/link-category, within the group marked by addToGroup (the group's name)
        let index = 0
        for (let i = 0; i < groups.value.length; i++) {
          if (groups.value[i].name === addToGroup.value) {
            index = i
            break
          }
        }
        await root.$feathers.service('system/link-category').patch(category.value, { $push: { [`groups.${index}.links`]: _id } })
      } else {
        // Patch the link in the system/link service
        await root.$feathers.service('system/link').patch(id, temp)
      }
      linkDialog.value = false
      loadLinks()
    }
    async function removeLink () {
      await root.$feathers.service('system/link').remove(editLink.value._id)
      const { groups } = await root.$feathers.service('system/link-category').get(category.value)
      const g = groups.map(({ name, links }) => { return { name, links: links.filter((link) => link !== editLink.value._id) } })
      await root.$feathers.service('system/link-category').patch(category.value, { groups: g })
      linkDialog.value = false
      loadLinks()
    }

    return {
      user,
      isAdmin,
      category,
      newCategory,
      categories,
      editRoles,
      roles,
      categoryDialog,
      categoryError,
      addCategory,
      addGroupDialog,
      groupName,
      addGroup,
      groups,
      hasRole,
      startEdit,
      linkDialog,
      editLink,
      addLink,
      saveLink,
      removeLink
    }
  }
}
</script>
